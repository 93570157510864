import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "subbody modulewrap"
};
const _hoisted_2 = ["innerHTML"];
import { FFmixin } from "@/mixin/mixin.js";
export default {
  __name: 'about',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      setTitle,
      getNavData,
      navData
    } = FFmixin();
    const getData = async () => {
      setTitle(2, 0, 0, () => {
        getNavData();
      });
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "subabout editor",
        innerHTML: _ctx.$common.langInfo(_unref(navData).navContent.content, _unref(navData).navContent.content_en)
      }, null, 8, _hoisted_2)]);
    };
  }
};